<template>
  <!-- 监控视屏 -->
  <div class="videopage">
    <!-- 返回上一页 -->
    <div class="back-but">
      <el-button type="info" icon="el-icon-back" @click="clickblack"
        >上一页</el-button
      >
    </div>
    <!-- 主体监控 -->
    <div>
      <ul class="winvideo">
        <li v-if="token">
          <iframe
            :src="
              `https://open.ys7.com/ezopen/h5/iframe_se?url=${urlArr}&audio=1&accessToken=` +
              token
            "
            width="80%"
            height="450"
            id="iframe"
            allowfullscreen
            allow="全屏"
          >
          </iframe>
        </li>
      </ul>
      <div class="butdiv">
        <ul class="butlist">
          <li v-for="(item, index) in ultlist" :key="index">
            <el-button type="info" @click="clickBut(item)">
              {{ item.regionName }}</el-button
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// vuex中的数据
import { getMonitoring, getToken } from '@/api/secondaryPage'
export default {
  // 定义数据
  data() {
    return {
      // 装入地址的数组
      urlArr: null,
      // 地址列表数据
      ultlist: [],
      token: null,
    }
  },
  //计算属性
  computed: {
    insNo() {
      return this.$route.query.insNo
    },
  },
  // dom加载成功
  mounted() {
    this.getDeviceList()
    let referrer = document.getElementById('referrer')
    referrer.content = 'origin'
  },
  // 函数
  methods: {
    // 获取到摄像头列表
    async getDeviceList() {
      const res = await getMonitoring({ insNo: this.insNo })
      const token = await getToken(this.insNo)
      if (token.data.code == 0) {
        this.token = JSON.parse(JSON.stringify(token.data.data))
        console.log(this.token)
      }
      if (res.data.data.code == 0) {
        if (res.data.data.data.length == 0) {
          this.urlArr = null
        } else {
          this.ultlist = res.data.data.data
          this.urlArr = res.data.data.data[0].url
        }
      }
    },
    // 点击按钮
    clickBut(item) {
      this.urlArr = item.url
    },
    // 返回上一页
    clickblack() {
      this.$router.go(-1)
    },
  },
  beforeDestroy() {
    let referrer = document.getElementById('referrer')
    referrer.content = 'no-referrer'
  },
}
</script>

<style scoped>
.videopage {
  height: 100%;
  background: url(./bigbg.png);
  position: relative;
}
/* 返回上一页 */
.back-but {
  line-height: 3rem;
  padding-left: 1.5rem;
}
/* 主体监控 */
.winvideo {
  display: flex;
  flex-wrap: wrap;
}
.winvideo li {
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  width: 70%;
  display: flex;
  justify-content: center;
}
/* 分页 */
.fen-page {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.butdiv {
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.butdiv .butlist {
  width: 70%;
  display: flex;
  /* display: -webkit-flex; */
  justify-content: space-evenly;
  align-items: center;
  /* flex-direction: row; */
  flex-wrap: wrap;
}
.butdiv .butlist > li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  width: 13rem;
}
.winvideo {
  display: flex;
  justify-content: center;
}
</style>
